import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

//redux
import { Provider } from 'react-redux';

import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { http } from './services/api.service';
import { authLogout } from './redux/auth/authAction';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import './i18n/index';
import 'react-photoswipe/lib/photoswipe.css';
import 'react-jinke-music-player/assets/index.css';

const element = ((<ReactJkMusicPlayer />), document.getElementById('root'));

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      localStorage.clear();
      store.dispatch(authLogout());
    }
    throw error;
  },
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  element,
);

serviceWorker.unregister();
