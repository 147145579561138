export const emptyRow = (item, children) => {
  if (item.id) {
    return children;
  } else {
    return {
      children: '',
      props: {
        colSpan: 0,
      },
    };
  }
};
