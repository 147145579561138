import { CallsService } from '../../services/calls.service';

export const FETCH_CALLS_FILTERS = 'FETCH_CALLS_FILTERS';
export const SET_CALLS_QUERY_PARAM = 'SET_CALLS_QUERY_PARAM';
export const FETCH_CALLS = 'FETCH_CALLS';
export const FETCH_CALLS_INFO = 'FETCH_CALLS_INFO';

export const fetchCalls = (params, resetData = true) => async dispatch => {
  const res = await CallsService.getCalls(params);
  dispatch({
    type: FETCH_CALLS,
    payload: {
      data: res.data?.data || [],
      dataLength: res.data.meta?.total || 0,
      dataTo: res.data.meta?.to || 0,
      resetData,
    },
  });
  dispatch({ type: FETCH_CALLS_INFO, payload: res.data.info });
};

export const fetchCallsFilters = () => async dispatch => {
  const res = await CallsService.getCallsFilters();
  dispatch({ type: FETCH_CALLS_FILTERS, payload: res.data.data });
};
