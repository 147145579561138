import { combineReducers } from 'redux';

//reducers
import authReducer from './auth/authReducer';
import { OperatorsReducer } from './operators/operators.reducer';
import { CommonReducer } from './common/common.reducer';
import { ClientReducer } from './clients/client.reducer';
import { BoxesReducer } from './boxes/boxes.reducer';
import { OffersReducer } from './offers/offers.reducer';
import { BranchReducer } from './branches/branches.reducer';
import { CountryReducer } from './countries/country.reducer';
import { LanguagesReducer } from './languages/languages.reducer';
import { RolesReducer } from './roles/roles.reducer';
import { StatusesReducer } from './statuses/statuses.reducer';
import { CategoriesReducer } from './categories/categories.reducer';
import { FlightReducer } from './flights/flights.reducer';
import { ShopsReducer } from './shops/shop.reducer';
import { OrderReducer } from './orders/orders.reducer';
import { PagesReducer } from './pages/page.reducer';
import { WarehouseReducer } from './warehouses/warehouses.reducer';
import { PaymentReducer } from './payments/payment.reducer';
import { TasksReducer } from './tasks/tasks.reducer';
import { MarketingReducer } from './marketing/marketing.reducer';
import { CellsReducer } from './cell/cell.reducer';
import { SiteSettingReducer } from './siteSetting/site.reducer';
import { DeliveryReducer } from './delivery/delivery.reducer';
import { CallReducer } from './call/call.reducer';
import { CallsReducer } from './calls/calls.reducer';
import { ChatReducer } from './chat/call.reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  operators: OperatorsReducer,
  clients: ClientReducer,
  boxes: BoxesReducer,
  common: CommonReducer,
  offers: OffersReducer,
  branches: BranchReducer,
  languages: LanguagesReducer,
  countries: CountryReducer,
  status: StatusesReducer,
  roles: RolesReducer,
  category: CategoriesReducer,
  flights: FlightReducer,
  shops: ShopsReducer,
  orders: OrderReducer,
  pages: PagesReducer,
  warehouse: WarehouseReducer,
  payment: PaymentReducer,
  tasks: TasksReducer,
  marketing: MarketingReducer,
  cell: CellsReducer,
  site: SiteSettingReducer,
  delivery: DeliveryReducer,
  call: CallReducer,
  calls: CallsReducer,
  chat: ChatReducer,
});
