import { http } from './api.service';

export const ChatService = {
  getAllChats(params) {
    return http.get(`/bot/all-chats`, { params });
  },
  getChatUserProfilePhoto(id) {
    return http.get(`/bot/chat/download/${id}`);
  },
  sendMessage(body) {
    return http.post('/bot/send-message', body);
  },
  editMessage(body) {
    return http.post('/bot/edit-message', body);
  },
  getAllMessages(id, page = 1) {
    return http.get(`/bot/chats/${id}?page=${page}`);
  },
  readMessage(chat_id, message_id) {
    return http.post(`/bot/chat/read/${chat_id}/${message_id}`);
  },
  getClients(params) {
    return http.get(`/bot/chat/client/search`, {
      params,
    });
  },
  attachClient(body) {
    return http.post('/bot/chat/attach/client', body);
  },
  getChatStatistics() {
    return http.get('/bot/chat/statistics');
  },
};
