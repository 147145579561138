import axios from 'axios';
import { http } from './api.service';

export const ClientService = {
  getClients(params) {
    return http.get('/clients', {
      params,
    });
  },
  getOrderStatuses(client, params) {
    return http.get(`/clients/${client}/get-orders-statuses`, {
      params,
    });
  },
  getClientSenders(id) {
    return http.get(`/scan/client/receivers-sender?type_id=1&sender_id=${id}`);
  },
  getClientReceivers(id) {
    return http.get(`/scan/client/receivers-sender?type_id=0&sender_id=${id}`);
  },
  getSenders(id) {
    return axios.get('https://turonexpress.com/api/senders/' + id);
  },
  getRecipients(id) {
    return axios.get('https://turonexpress.com/api/recipients/' + id);
  },
  getClientById(id, params) {
    return http.get('/clients/' + id, {
      params,
    });
  },
  createClient(client) {
    return http.post('/clients', client);
  },
  updateClient(client) {
    return http.put(`/clients/${client.id}`, client);
  },
  deleteClients(ids) {
    return http.delete(`/clients/${ids.join(',')}`);
  },
  addClientAddress(id, address) {
    return http.post(`/clients/${id}/address`, address);
  },
  updateClientAddress(id, address) {
    return http.put(`/clients/${id}/address/${address.id}`, address);
  },
  deleteClientAddress(id, addressId) {
    return http.delete(`/clients/${id}/address/${addressId}/delete`);
  },
  getClientFilters(params) {
    return http.get('/clients/filter-list', { params });
  },
  searchClients(params) {
    return http.get('/clients/search', {
      params,
    });
  },
  sendMessage(data) {
    return http.post('/clients/send-sms', data);
  },
  sendPaymentInfo(data) {
    return http.post('/invoice/orders', data);
  },
  getOrders(client, params) {
    return http.get(`/clients/${client}/get-orders`, { params });
  },
  getPayments(id, params) {
    return http.get(`/clients/${id}/payments`, { params });
  },
  sendRequest(data) {
    return http.post('/clients/send-fill-request', data);
  },
  getReceivers(id) {
    return http.get(`/clients/${id}/receivers`);
  },
  getBalance(data) {
    return http.post('/clients/get-balance', data);
  },
  getOneTimeToken(id) {
    return http.get('/get-ot-token/' + id);
  },
  withdraw(data) {
    return axios.post('https://turonexpress.com/api/withdraw', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },
  combineClients(data) {
    return http.post('/clients/combine', data);
  },
  updateLocationClients(id, location) {
    return http.post(`clients/${id}/update-address`, location);
  },
  getLocationsClient(id) {
    return http.get(`clients/${id}/get-location`);
  },
  updateClientCustomsStatus(client, status) {
    return http.put(`clients/${client.id}/update-customs-status`, {
      exceeded: status,
    });
  },
  updateClientNotificationAbout(client, status) {
    return http.put(`clients/${client.id}/update-about-client-status`, {
      switch: status,
    });
  },
  getDistricts(search) {
    return http.get(`/districts-uz${search ? '?search=' + search : ''}`);
  },
};
