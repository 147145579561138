import {
  changeMessage,
  defineMediaType,
  resortRoom,
  resortRooms,
} from '../../utils/chat';
import {
  SET_CONNECTED,
  SET_PUSHER,
  SET_LOADING,
  SET_ROOMS,
  SET_NEW_ROOMS,
  SET_ALLMESSAGESOFCHAT,
  SET_CLIENT_NEW_MESSAGE,
  SET_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE_ROOMS,
  SET_CLIENTS,
  SET_CLIENTS_LOADING,
} from './call.actions';

const initialState = {
  pusher: null,
  connected: false,
  loading: false,
  rooms: [],
  messages: [],
  meta: {},
  roomsMeta: {},
  newRoom: {},
  clients: [],
  clientsLoading: false,
};

export const ChatReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONNECTED:
      return {
        ...state,
        connected: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_ROOMS:
      const result = resortRooms(payload?.data?.data);

      return {
        ...state,
        rooms: payload.resetData ? result : [...state.rooms, ...result],
        roomsMeta: payload?.data?.meta,
      };
    case SET_NEW_ROOMS:
      const room = resortRoom(payload);

      return {
        ...state,
        rooms: [room, ...state.rooms],
        newRoom: room,
      };
    case UPDATE_ROOMS:
      return {
        ...state,
        rooms: payload,
      };
    case SET_ALLMESSAGESOFCHAT:
      const resultMessages = payload?.data?.data?.map(item =>
        defineMediaType(item),
      );

      return {
        ...state,
        messages: payload.resetData
          ? resultMessages.reverse()
          : [...resultMessages.reverse(), ...state.messages],
        meta: payload?.data?.meta,
      };
    case SET_MESSAGE:
      let oldMessages = [...state.messages].slice(0, -1);

      return {
        ...state,
        messages: payload.removeLoading
          ? [...oldMessages, payload.message]
          : [...state.messages, payload.message],
      };
    case UPDATE_MESSAGE:
      const updatedMessage = changeMessage({
        messages: [...state.messages],
        message: payload,
      });

      return {
        ...state,
        messages: updatedMessage,
      };
    case SET_CLIENT_NEW_MESSAGE:
      return {
        ...state,
        clientNewMessage: payload,
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: payload,
      };
    case SET_CLIENTS_LOADING:
      return {
        ...state,
        clientsLoading: payload,
      };
    case SET_PUSHER:
      return {
        ...state,
        pusher: payload,
      };
    default:
      return state;
  }
};
