import { http } from './api.service';

export const CallsService = {
  getCalls(params) {
    return http.get('/ip-tel', {
      params,
    });
  },
  getCallsFilters() {
    return http.get('/ip-tel/filter-list');
  },
  getAduios(extra_id) {
    return http.get('/ip-tel/' + extra_id);
  },
  callNowArm(phone_number) {
    return http.post('/ip-tel/call/now', {
      to: phone_number,
    });
  },
};
