export const config = {
  API_ROOT:
    process.env.REACT_APP_STAGE === 'development'
      ? 'https://api.logi.uz'
      : 'https://api.turonexpress.com',
  API_WEBSOCKET:
    process.env.REACT_APP_STAGE === 'development'
      ? 'api.logi.uz'
      : 'api.turonexpress.com',
  VERSION: 'v2',
};
