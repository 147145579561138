import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Input, Row, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { UnitComponent } from '../../components/Unit/Unit';
import { SVGIcon } from '../../components/SVGIcon/SVGIcon';
import {
  ORDER_STATUS_CLASS_NAMES,
  ORDER_TYPE_ICONS,
} from '../../constants/orders';
import {
  fetchClientOrders,
  RESET_CLIENT_ORDERS,
} from '../../redux/clients/client.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getterState } from '../../utils/getter';
import GenerateSkeleton from '../../components/GeneratorSkeleton/GeneratorSkeleton';
import { debounce } from '../../utils/debounce';
import { useHistory } from 'react-router-dom';

const CallClientOrdersTab = ({ item }) => {
  const [t] = useTranslation();
  const clientOrders = useSelector(getterState('clients.clientOrders'));
  const clientOrdersLastPage = useSelector(
    getterState('clients.clientOrdersLastPage'),
  );
  const [page, setPage] = useState(1);
  const [isSent, setIsSent] = useState(false);
  const [isClient, setIsClient] = useState(true);
  const [isSearched, setIsSearched] = useState(false);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const statuses = useSelector(state => state.clients.statuses);
  const searchInput = useRef();
  const history = useHistory();

  const tableColumns = [
    {
      title: t('type'),
      render: item => (
        <SVGIcon
          iconPath={ORDER_TYPE_ICONS[item.type?.key]}
          className="text-secondary"
        />
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: t('coming_from'),
      dataIndex: 'from_address',
    },
    {
      title: t('box'),
      render: item => (
        <div className="text-violet">
          {item.boxOrders?.length ? item.boxOrders[0].box_codename : t('no')}
        </div>
      ),
    },
    {
      title: t('flight'),
      render: item => (
        <div>
          <span>
            {item.boxOrders?.length ? item.boxOrders[0]?.flight_name : t('no')}
          </span>
          <div className="text-secondary">
            {item.boxOrders?.length
              ? item.boxOrders[0]?.flight_departure_time
              : t('no')}
          </div>
        </div>
      ),
    },
    {
      title: t('total_weight'),
      width: 70,
      render: item => <UnitComponent value={item.weight} type="weight" />,
    },
    {
      title: t('sum'),
      render: item => (
        <div className={`text-success`}>
          <UnitComponent value={item.amount} type="usd" />
        </div>
      ),
    },
    {
      title: t('created_at'),
      render: item => <div className="text-secondary">{item.created_at}</div>,
    },
    {
      title: t('customs_at'),
      render: item => <div className="text-secondary">{item.customs_at}</div>,
    },
    {
      title: t('status'),
      width: 130,
      render: item => (
        <div
          className={`status-btn btn-${
            ORDER_STATUS_CLASS_NAMES[item.status?.id]
          }`}
        >
          {item.status?.title}
        </div>
      ),
    },
  ];

  const loadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    setLoading(true);
    fetchClientOrders(
      item,
      {
        page: newPage,
        is_received: !isSent,
        is_client: isClient,
      },
      false,
    )(dispatch).finally(() => setLoading(false));
  };

  const delaySearchOrders = debounce(val => {
    setIsSearched(!!val.length);
    setLoading(true);
    fetchClientOrders(item, {
      'filter[search]': val.length ? val : undefined,
      is_received: !isSent,
      is_client: isClient,
    })(dispatch).finally(() => setLoading(false));
  }, 1500);

  const handleSearchOrders = e => {
    const searchVal = e.target.value;
    delaySearchOrders(searchVal);
  };

  const onSearchClear = () => {
    searchInput.current.state.value = '';
    setIsSearched(false);
    setLoading(true);
    fetchClientOrders(item, {
      is_received: Number(!isSent),
      is_client: Number(isClient),
    })(dispatch).finally(() => setLoading(false));
  };

  const searchedTable = useMemo(
    () =>
      !loading ? (
        <>
          <Table
            pagination={false}
            className="no-header-table m-t-10"
            columns={tableColumns}
            dataSource={clientOrders.map(order => ({
              key: order.id,
              ...order,
            }))}
            locale={{
              emptyText: () => '',
            }}
            onRow={record => ({
              onClick: () =>
                history.push('/admin/orders?order_id=' + record?.id),
            })}
          />
          <Row justify="center">
            <Button
              className="input-size-button m-t-15"
              onClick={onSearchClear}
              type="primary"
            >
              {t('reset')}
            </Button>
          </Row>
        </>
      ) : null,
    [loading],
  );

  const handleIsSent = type => {
    setIsSent(type);
    setIsClient(false);
  };

  const checkStatusId = statusId => filter.includes(statusId);
  const findAndRemoveNumber = target => {
    if (filter.includes(target)) {
      setFilter(filter.filter(item => item !== target));
      return true;
    }
    return false;
  };

  const handleStatus = newValue => {
    if (checkStatusId(newValue)) {
      findAndRemoveNumber(newValue);
    } else {
      setFilter(oldArray => [...oldArray, newValue]);
    }
  };

  useEffect(() => {
    const params = isClient
      ? { is_client: Number(!isClient), 'filter[status_id]': filter }
      : { is_received: Number(!isSent), 'filter[status_id]': filter };
    if (isSearched) return;
    if (item) {
      setLoading(true);
      fetchClientOrders(
        item,
        params,
      )(dispatch).finally(() => setLoading(false));
    }
    return () => dispatch({ type: RESET_CLIENT_ORDERS });
  }, [isSent, item, isClient, filter]);

  return (
    <div className="client-orders-tab">
      <Row>
        <Spin spinning={loading}>
          <Col>
            <div className="d-flex p-l-20">
              {statuses?.map(item => (
                <span
                  className={`p-l-15 p-r-15 p-b-5 p-t-5 pointer status-btn btn-${
                    ORDER_STATUS_CLASS_NAMES[item?.id]
                  }`}
                  style={{
                    width: '130px',
                    padding: '10px',
                    margin: '5px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                    border: `2px solid ${
                      checkStatusId(item?.id) ? '#74B7FF' : 'white'
                    }`,
                  }}
                  onClick={() => handleStatus(item?.id)}
                >
                  {item?.title} {item?.orders_count}
                </span>
              ))}
            </div>
          </Col>
        </Spin>
      </Row>
      <br />
      <br />
      <Row justify="space-between">
        <Col>
          <div className="d-flex p-l-20">
            <span
              className={
                'p-l-15 p-r-15 p-b-5 p-t-5 pointer ' +
                (isClient
                  ? 'text-secondary'
                  : !isSent
                  ? 'btn-primary-light'
                  : 'text-secondary')
              }
              onClick={() => handleIsSent(false)}
            >
              {t('received')}
            </span>
            <span
              className={
                'p-l-15 p-r-15 p-b-5 p-t-5 pointer ' +
                (isClient
                  ? 'text-secondary'
                  : isSent
                  ? 'btn-primary-light'
                  : 'text-secondary')
              }
              onClick={() => handleIsSent(true)}
            >
              {t('sent_items')}
            </span>
            <span
              className={
                'p-l-15 p-r-15 p-b-5 p-t-5 pointer ' +
                (isClient ? 'btn-primary-light' : 'text-secondary')
              }
              onClick={() => setIsClient(true)}
            >
              {t('client')}
            </span>
          </div>
        </Col>
        <Col span={6}>
          <div className="inputsSearch inputsSearch-small p-r-20">
            <SVGIcon iconPath="search" />
            <Input
              placeholder={t('search')}
              onChange={handleSearchOrders}
              ref={searchInput}
            />
          </div>
        </Col>
      </Row>
      {isSearched
        ? searchedTable
        : clientOrders?.map((yearOrder, yIdx) => {
            return (
              <Fragment key={yIdx}>
                <Row className="order-table-row">
                  <Col span={5}>
                    <div className="text-primary">{yearOrder.name}</div>
                  </Col>
                  <Col span={5}>
                    <div>
                      <span>{t('quantity')}</span>
                      <span className="text-primary"> {yearOrder.qty}</span>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="d-flex justify-space-between">
                      <span>{t('price_to_year')}</span>
                      <span className="text-primary">
                        <UnitComponent
                          value={yearOrder.total_price}
                          type="usd"
                        />
                      </span>
                    </div>
                  </Col>
                </Row>
                {yearOrder.quarters.map((quarterOrder, qIdx) => {
                  return (
                    <Fragment key={qIdx + yIdx}>
                      <Row className="order-table-row bg-secondary-light">
                        <Col span={5}>
                          <div className="text-secondary">
                            {quarterOrder.name + ` ${t('quarter')}`}{' '}
                            {yearOrder.name}
                          </div>
                        </Col>
                        <Col span={5}>
                          <div>
                            <span className="text-secondary">
                              {t('quantity')}
                            </span>
                            <span> {quarterOrder.qty}</span>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="d-flex justify-space-between">
                            <span className="text-secondary">
                              {t('price_to_quarter')}
                            </span>
                            <span>
                              <UnitComponent
                                value={quarterOrder.total_price}
                                type="usd"
                              />
                            </span>
                          </div>
                        </Col>
                        <Col span={6}>
                          {isSent && (
                            <div className="d-flex justify-space-between m-l-15">
                              <span className="text-secondary">
                                {t('available')}
                              </span>
                              <span
                                className={`text-${
                                  quarterOrder.available_price > 0
                                    ? 'success'
                                    : 'danger'
                                }`}
                              >
                                <UnitComponent
                                  value={quarterOrder.available_price}
                                  type="usd"
                                />
                              </span>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Table
                        pagination={false}
                        className="no-header-table"
                        columns={tableColumns}
                        dataSource={quarterOrder.orders.map(order => ({
                          key: order.id,
                          ...order,
                        }))}
                        locale={{
                          emptyText: () => '',
                        }}
                        onRow={record => ({
                          onClick: () =>
                            history.push(
                              '/admin/orders?order_id=' + record?.id,
                            ),
                        })}
                      />
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}

      {loading && (
        <GenerateSkeleton
          cols={new Array(tableColumns.length + 1).fill({})}
          rows={3}
        />
      )}
      {clientOrdersLastPage > page && !loading && (
        <Row justify="center">
          <Button onClick={loadMore} className="m-t-20">
            {`${t('download')} ${t('more')}`}
          </Button>
        </Row>
      )}
    </div>
  );
};

export default CallClientOrdersTab;
