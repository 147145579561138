import React from 'react';
import { Skeleton, Space } from 'antd';

const GenerateSkeleton = ({ size, activeMode, cols, rows }) => {
  const active = activeMode === undefined ? true : activeMode;
  return (
    <>
      {new Array(rows).fill('').map((row, i) => (
        <div key={i} className="spaceSkeleton">
          {cols.map((col, index) => (
            <Space key={`${i}-${index}`} style={{ width: col.width || '100%' }}>
              <Skeleton.Button active={active} size={size} />
            </Space>
          ))}
        </div>
      ))}
    </>
  );
};

export default GenerateSkeleton;
