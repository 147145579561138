export const ORDER_TYPE_OFFICE = 0;
export const ORDER_TYPE_PICKUP = 1;
export const ORDER_TYPE_INTERNET = 2;
export const ORDER_TYPE_FEDEX = 3;
export const ORDER_TYPE_UPS = 4;
export const ORDER_TYPE_SELF_DELIVERY = 5;

export const ORDER_STATUS_PACKING = 1;
export const ORDER_STATUS_SENT = 2;
export const ORDER_STATUS_PENDING = 3;
export const ORDER_STATUS_AT_CUSTOMS = 4;
export const ORDER_STATUS_CANCEL = 5;
export const ORDER_STATUS_IN_STOCK = 6;
export const ORDER_STATUS_ARRIVED = 7;
export const ORDER_STATUS_ADDED = 31;
export const ORDER_STATUS_DONE = 30;

export const ORDER_RECEIVER_PHONE_BASE = 2;
export const ORDER_RECEIVER_PHONE_ADDITIONAL = 3;
export const ORDER_SENDER_PHONE_BASE = 4;
export const ORDER_SENDER_PHONE_ADDITIONAL = 5;

export const ORDER_TYPE_ICONS = {
  [ORDER_TYPE_OFFICE]: 'house',
  [ORDER_TYPE_PICKUP]: 'truck',
  [ORDER_TYPE_INTERNET]: 'cart',
  [ORDER_TYPE_FEDEX]: 'fedex',
  [ORDER_TYPE_UPS]: 'ups',
  [ORDER_TYPE_SELF_DELIVERY]: 'user',
};

export const ORDER_STATUS_CLASS_NAMES = {
  [ORDER_STATUS_PACKING]: 'secondary-light',
  [ORDER_STATUS_SENT]: 'violet-light',
  [ORDER_STATUS_PENDING]: 'primary-light',
  [ORDER_STATUS_AT_CUSTOMS]: 'blue',
  [ORDER_STATUS_CANCEL]: 'danger-light',
  [ORDER_STATUS_IN_STOCK]: 'warning-light',
  [ORDER_STATUS_ARRIVED]: 'blue-light',
  [ORDER_STATUS_ADDED]: 'secondary',
  [ORDER_STATUS_DONE]: 'success-light',
};
