export const mediaUrl = item => {
  return `http://api.turonexpress.com/bot/chat/media/download?chat_id=${item?.chat_id}&message_id=${item?.message_id}`;
};

export const resortRooms = rooms => {
  return rooms.map(item => {
    return {
      roomId: item?.chat_id + '',
      roomName: item?.first_name,
      avatar: 'https://api.turonexpress.com/bot/chat/download/' + item?.chat_id,
      unreadCount: +item?.unread_messages_count,
      lastMessage: {
        _id: item?.last_message?.message_id,
        content: item?.last_message?.text,
        senderId: item?.last_message?.user_id,
        username: item?.username,
        timestamp: item?.last_message?.time,
        saved: true,
        distributed: false,
        seen: false,
        new: true,
      },
      users: [],
    };
  });
};

export const resortRoom = room => {
  return {
    roomId: room?.chat_id + '',
    roomName: room?.first_name,
    avatar: 'https://api.turonexpress.com/bot/chat/download/' + room?.chat_id,
    unreadCount: 0,
    lastMessage: {
      _id: room?.last_message?.message_id,
      content: room?.last_message?.text,
      senderId: room?.last_message?.user_id,
      username: room?.username,
      timestamp: room?.last_message?.time,
      saved: true,
      distributed: false,
      seen: false,
      new: true,
    },
    users: [],
  };
};

export const resortNotice = chatList => {
  return chatList.sort((a, b) => {
    if (a.unreadCount && b.unreadCount) {
      return b.unreadCount - a.unreadCount;
    }
    // If only 'a' has unreadCount, 'a' should come first
    if (a.unreadCount) {
      return -1;
    }
    // If only 'b' has unreadCount, 'b' should come first
    if (b.unreadCount) {
      return 1;
    }
    // If neither have unreadCount, keep the order the same
    return 0;
  });
};

export const addNotice = (rooms, newMessage, roomId) => {
  const result = resortNotice(
    rooms.map(item => {
      if (item?.roomId == newMessage?.chat_id) {
        return {
          ...item,
          unreadCount:
            newMessage?.chat_id != roomId ? +item.unreadCount + 1 : null,
          lastMessage: {
            _id: newMessage?.message_id,
            content: newMessage?.text,
            senderId: newMessage?.from_id,
            username: newMessage?.username,
            timestamp: newMessage?.time,
            saved: true,
            distributed: true,
          },
        };
      } else {
        return item;
      }
    }),
  );

  return result;
};

export const checkFileType = (message, formData) => {
  let fileType = message?.files[0]?.type?.split('/')[0];
  let file = message?.files[0]?.blob;

  switch (fileType) {
    case 'image':
      formData.append('type', 'photo');
      formData.append('photo', file);
      break;
    case 'application':
      formData.append('type', 'document');
      formData.append('document', file);
      break;
    case 'audio':
      formData.append('type', 'voice');
      formData.append('voice', file);
      break;
    case 'video':
      formData.append('type', 'video');
      formData.append('video', file);
      break;
    case 'animation':
      formData.append('type', 'animation');
      formData.append('animation', file);
      break;
    case 'sticker':
      formData.append('type', 'sticker');
      formData.append('sticker', file);
      break;
    case 'video_note':
      formData.append('type', 'video_note');
      formData.append('video_note', file);
      break;
    case 'contact':
      formData.append('type', 'contact');
      formData.append('contact', file);
      break;
    case 'location':
      formData.append('type', 'location');
      formData.append('location', file);
      break;
    default:
      // Handle default case if needed
      break;
  }
};

export const changeMessage = ({ messages, message }) => {
  return messages?.map(item => {
    if (item?._id === message.message_id) {
      return {
        _id: message.message_id,
        content: message?.text || message?.caption,
        senderId: message?.from_id + '',
        date: message?.created_at,
        timestamp: message?.time,
      };
    } else {
      return item;
    }
  });
};

export const defineMediaType = item => {
  let defaultItem = {
    _id: item?.message_id,
    content: item?.text || item?.caption,
    senderId: item?.from_id + '',
    date: item?.created_at,
    timestamp: item?.time,
    replyMessage: item?.replied_message
      ? defineMediaType(item?.replied_message)
      : null,
  };

  switch (item?.type) {
    case 'photo':
      return {
        ...defaultItem,
        files: [
          {
            name: item?.photo?.file_name,
            size: item?.photo[3]?.file_size,
            type: item?.ext ?? item?.file_path?.split('.')[1],
            url: mediaUrl(item),
          },
        ],
      };
    case 'video':
    case 'video_note':
      return {
        ...defaultItem,
        files: [
          {
            name: item?.video?.file_name || item?.video_note?.file_name,
            size: item?.video?.file_size || item?.video_note?.file_size,
            type: item?.ext,
            duration: item?.video?.duration || item?.video_note?.duration,
            url: mediaUrl(item),
          },
        ],
      };
    case 'document':
      return {
        ...defaultItem,
        files: [
          {
            name: item?.document?.file_name,
            size: item?.document?.file_size,
            type: item?.ext,
            url: mediaUrl(item),
          },
        ],
      };
    case 'audio':
      return {
        ...defaultItem,
        files: [
          {
            name: item?.audio?.file_name,
            size: item?.audio?.file_size,
            type: item?.ext,
            url: mediaUrl(item),
            audio: true,
          },
        ],
      };
    case 'voice':
      return {
        ...defaultItem,
        files: [
          {
            name: item?.audio?.file_name || 'voice',
            size: item?.audio?.file_size,
            type: 'mp3',
            url: mediaUrl(item),
            audio: true,
          },
        ],
      };
    case 'sticker':
      return {
        ...defaultItem,
        files: [
          {
            name: item?.sticker?.file_name,
            size: item?.sticker?.file_size,
            type: item?.ext,
            url: mediaUrl(item),
          },
        ],
      };
    case 'contact':
      return {
        _id: item?.message_id,
        content:
          '#### **' +
          item?.contact?.first_name +
          '**' +
          '`' +
          item?.contact?.phone_number +
          '`',
        senderId: item?.from_id + '',
        date: item?.created_at,
        timestamp: item?.time,
        replyMessage: item?.replied_message
          ? defineMediaType(item?.replied_message)
          : null,
      };
    default:
      return defaultItem;
  }
};

export const removeNotice = (rooms, roomId) => {
  return rooms.map(item => {
    if (item?.roomId == roomId) {
      return {
        ...item,
        unreadCount: '',
      };
    } else {
      return item;
    }
  });
};
