import React, { useEffect } from 'react';
import { MinusOutlined } from '@ant-design/icons';
import soundFile from '../../assets/sounds/3.mp3';
import { Button } from 'antd';

const CallAnimation = ({
  callModal,
  phoneNumber,
  closeModal,
  called_number,
  audioStart,
  event,
  hangup_cause,
}) => {
  useEffect(() => {
    if (callModal) {
      audioStart.current.play();
    } else {
      audioStart.current.pause();
    }
  }, [callModal]);

  useEffect(() => {
    if (callModal) {
      setTimeout(() => {
        audioStart.current.play();
      }, 3000);
    } else {
      audioStart.current.pause();
    }
  }, [callModal]);

  return (
    <div>
      <audio ref={audioStart} src={soundFile} loop />
      <div class="seperator-wrapper">
        <div class="seperator gradient"></div>
      </div>

      <div className="call-modal__header">
        <div className="call-modal__title">
          <MinusOutlined
            onClick={closeModal}
            color="#eb2f96"
            style={{
              color: 'white',
              background: '#007BFF',
              fontSize: 18,
              borderRadius: 5,
              padding: 2,
            }}
          />
          <h3>{`Номер +${phoneNumber ?? '--'} ➡️ +${
            called_number ?? '--'
          }`}</h3>
        </div>
        {event === 4 || event === 5 ? (
          <Button
            className={'call-modal__button'}
            danger
            type="primary"
            size="large"
            style={{ borderRadius: 6 }}
          >
            {hangup_cause}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default CallAnimation;
