import { BatchesService } from '../../services/batches.service';

export const FETCH_BATCHES = 'FETCH_BATCHES';
export const FETCH_BATCH_FILTERS = 'FETCH_BATCH_FILTERS';
export const FETCH_BATCH_INFO = 'FETCH_BATCH_INFO';
export const SET_BATCH_QUERY_PARAM = 'SET_BATCH_QUERY_PARAM';
export const CREATE_BATCH = 'CREATE_BATCH';
export const UPDATE_BATCH = 'UPDATE_BATCH';
export const DELETE_BATCHES = 'DELETE_BATCHES';
export const BATCH_PAYMENT = 'BATCH_PAYMENT';

export const fetchBatches = (params, resetData = true) => async dispatch => {
  const res = await BatchesService.getBatches(params);
  dispatch({
    type: FETCH_BATCHES,
    payload: {
      data: res.data?.data || [],
      dataLength: res.data.meta?.total || 0,
      dataTo: res.data.meta?.to || 0,
      resetData,
    },
  });
  dispatch({ type: FETCH_BATCH_INFO, payload: res.data.info });
};

export const fetchBatchFilters = () => async dispatch => {
  const res = await BatchesService.getBatchFilters();
  dispatch({ type: FETCH_BATCH_FILTERS, payload: res.data.data });
};

export const createBatch = batch => async dispatch => {
  const res = await BatchesService.createBatch(batch);
  dispatch({ type: CREATE_BATCH, payload: res.data.data });
  return res.data.message;
};

export const updateBatch = batch => async dispatch => {
  const res = await BatchesService.updateBatch(batch);
  dispatch({ type: UPDATE_BATCH, payload: res.data.data });
  return res.data.message;
};

export const deleteBatches = items => async dispatch => {
  const ids = items.map(item => item.id);
  const res = await BatchesService.deleteBatchs(ids);
  dispatch({ type: DELETE_BATCHES, payload: ids });
  return res.data.message;
};

export const batchPayment = payment => async dispatch => {
  const res = await BatchesService.payment(payment.batch_id, payment);
  dispatch({
    type: BATCH_PAYMENT,
    payload: { data: res.data.data, id: payment.batch_id },
  });
  return res.data;
};
