import {
  CHECK_CALL,
  OPEN_MODAL,
  SET_CONNECTED,
  SET_DATA,
  SET_PUSHER,
  MIN_MODAL,
} from './call.actions';

const initialState = {
  modal: false,
  pusher: null,
  connected: false,
  data: {},
  reCall: false,
  minModal: false,
};

export const CallReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        modal: payload,
      };
    case MIN_MODAL:
      return {
        ...state,
        minModal: payload,
      };
    case CHECK_CALL:
      return {
        ...state,
        reCall: payload,
      };
    case SET_CONNECTED:
      return {
        ...state,
        connected: payload,
      };
    case SET_DATA:
      return {
        ...state,
        data: payload,
      };
    case SET_PUSHER:
      return {
        ...state,
        pusher: payload,
      };
    default:
      return state;
  }
};
