import {
  FETCH_CALLS,
  FETCH_CALLS_FILTERS,
  FETCH_CALLS_INFO,
  SET_CALLS_QUERY_PARAM,
} from './calls.actions';

const initialState = {
  calls: [],
  filters: [],
  info: [],
  queryParams: {},
  callsLength: 0,
  callsTo: 0,
};

export const CallsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CALLS:
      return {
        ...state,
        calls: payload.resetData
          ? payload.data
          : [...state.calls, ...payload.data],
        callsLength: payload.dataLength,
        callsTo: payload.dataTo,
      };

    case FETCH_CALLS_INFO:
      return { ...state, info: payload };

    case SET_CALLS_QUERY_PARAM:
      const isEmpty = payload && !Object.keys(payload).length;
      console.log('tttttttt', { isEmpty, payload });
      return {
        ...state,
        queryParams: isEmpty
          ? {}
          : {
              ...state.queryParams,
              ...payload,
            },
      };

    case FETCH_CALLS_FILTERS:
      return { ...state, filters: payload };
    default:
      return state;
  }
};
