import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Drawer, Row, Space, Statistic } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { SVGIcon } from '../components/SVGIcon/SVGIcon';
import { handleCallModal, handleMinModal } from '../redux/call/call.actions';
import { connect, useDispatch } from 'react-redux';
import CallAnimation from '../containers/Call/CallAnimation';
import '../containers/Call/CallAnimation.scss';
import soundFile from '../assets/sounds/2.mp3';
import CallModal from '../containers/Call/CallModal';
import { ClientService } from '../services/client.service';
import CallClientOrdersTab from '../containers/Call/CallClientOrdersTab';
import { RESET_CLIENT_ORDERS } from '../redux/clients/client.actions';

const Call = ({ callModal, callData, userData, minModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientReceived, setClientReceived] = useState(null);
  const dispatch = useDispatch();
  const audioStart = useRef(null);
  const audioEnd = useRef(null);

  const client = callData?.clients?.length > 0 ? callData?.clients[0] : {};

  const closeModal = () => {
    handleMinModal(true)(dispatch);
  };
  const showModal = () => setIsModalOpen(true);

  console.log('start');

  if (userData?.operator) {
    console.log('1');
    if (userData?.direction == 2 && callData?.operator == userData?.operator) {
      console.log('direction - close');
      setTimeout(() => {
        handleCallModal(false)(dispatch);
        handleMinModal(false)(dispatch);
        audioEnd.current.pause();
        setIsModalOpen(false);
        localStorage.removeItem('call-data');
      }, 3000);
    } else {
      if (callData?.event === 1 || callData?.event === 2) {
        console.log('event - 1 or 2');
        handleCallModal(true)(dispatch);
      } else if (callData?.event === 3) {
        console.log('event - 3');
        if (callData?.operator == userData?.operator) {
          console.log('event - 3 false');
          if (audioStart?.current) {
            audioStart.current.pause();
          }
        } else {
          if (callModal) {
            console.log('event - 3 true');
            setTimeout(() => {
              handleCallModal(false)(dispatch);
              handleMinModal(false)(dispatch);
              dispatch({ type: RESET_CLIENT_ORDERS });
              audioEnd.current.pause();
              setIsModalOpen(false);
              localStorage.removeItem('call-data');
            }, 3000);
          } else {
            console.log('event - 3 nothing');
          }
        }
      } else if (callData?.event === 4 || callData?.event === 5) {
        console.log('event - 4 or 5');
        audioEnd.current.play();
        if (audioStart?.current) {
          audioStart.current.pause();
        }
        setTimeout(() => {
          handleCallModal(false)(dispatch);
          handleMinModal(false)(dispatch);
          dispatch({ type: RESET_CLIENT_ORDERS });
          audioEnd.current.pause();
          setIsModalOpen(false);
          localStorage.removeItem('call-data');
        }, 5000);
      }
    }
  } else {
    console.log(null + ' 1');
  }

  const fetchClientById = async id => {
    setClientReceived(null);
    const res = await ClientService.getClientById(id);
    setClientReceived(res.data.data);
  };

  const client_id = callData?.client?.id;
  let clientAnother_id;

  if (callData && callData.clients && callData.clients.length > 0) {
    clientAnother_id = callData.clients[0].id;
  } else {
    clientAnother_id = null;
  }

  useEffect(() => {
    if (client_id) {
      fetchClientById(client_id);
    } else if (clientAnother_id) {
      fetchClientById(clientAnother_id);
    }
    return () => dispatch({ type: RESET_CLIENT_ORDERS });
  }, [client_id, clientAnother_id]);

  return (
    <div className="call-modal">
      <audio ref={audioEnd} src={soundFile} loop />
      <Drawer
        title={
          <CallAnimation
            callModal={callModal}
            phoneNumber={callData?.phone}
            called_number={callData?.called_number}
            closeModal={closeModal}
            audioStart={audioStart}
            event={callData?.event}
            hangup_cause={callData?.hangup_cause}
          />
        }
        width={1400}
        closable={false}
        onClose={closeModal}
        visible={minModal ? false : callModal}
        className="call-modal"
      >
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <Row gutter={16}>
            <Col span={12} className={`p-r-5`}>
              <div className={`bg-success-light b-r-10 p-15`}>
                <div className="call-modal__client">
                  <Statistic
                    title="Клиент"
                    value={`[ID: ${callData?.client?.id ?? '--'} : ${
                      callData?.client?.letter_id ?? '--'
                    }] ${callData?.client?.first_name ?? '--'} ${
                      callData?.client?.last_name ?? '--'
                    }`}
                    valueStyle={{ color: '#35483c', fontSize: '20.5px' }}
                    prefix={<SVGIcon iconPath={'user'} />}
                  />
                  <a
                    href={`/admin/clients?client_id=${callData?.client?.id}&general`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SVGIcon
                      className="call-modal__client-show"
                      iconPath={'show'}
                    />
                  </a>
                </div>
                <Statistic
                  title="Телефон"
                  value={` +${callData?.phone ?? '--'}`}
                  valueStyle={{ color: '#35483c', fontSize: '20.5px' }}
                  prefix={<PhoneOutlined />}
                />
              </div>
            </Col>
            <Col span={12} className={`p-r-5`}>
              <div className={`bg-success-light b-r-10 p-15 h-full`}>
                {callData?.clients?.length > 0 ? (
                  <>
                    <Statistic
                      title="Клиент"
                      value={`[ID: +${client?.id ?? '--'}] ${
                        client?.first_name ?? '--'
                      } ${client?.last_name ?? '--'}`}
                      valueStyle={{ color: '#35483c', fontSize: '20.5px' }}
                      prefix={<SVGIcon iconPath={'user'} />}
                    />

                    <div className="call-modal__clients">
                      <Button
                        style={{ borderRadius: 6 }}
                        type="primary"
                        onClick={showModal}
                      >
                        Больше клиентов
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="h-full w-full">
                    <h2 className="text-center">Не клиенты</h2>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <CallClientOrdersTab item={clientReceived} />
        </Space>
      </Drawer>
      <CallModal open={isModalOpen} setOpen={setIsModalOpen} data={callData} />
    </div>
  );
};

const mapStateToProps = state => ({
  callModal: state.call.modal,
  callData: state.call.data,
  userData: state.auth.user,
  minModal: state.call.minModal,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Call);
