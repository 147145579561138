import { Modal, Table } from 'antd';
import React from 'react';
import { SVGIcon } from '../../components/SVGIcon/SVGIcon';
import { emptyRow } from '../../utils';
import { useTranslation } from 'react-i18next';

const CallModal = ({ open, setOpen, data }) => {
  const [t] = useTranslation();
  const handleCancel = () => setOpen(false);

  const tableColumns = [
    {
      title: 'ID',
      fixed: 'left',
      align: 'center',
      width: 100,
      render: item => {
        const row = (
          <>
            <div className="top">{item.id}</div>
            <div className="bottom text-small text-secondary">
              {item.letter_id ?? '-'}
            </div>
          </>
        );
        return emptyRow(item, row);
      },
    },
    {
      title: t('first_name'),
      dataIndex: 'first_name',
    },
    {
      title: t('last_name'),
      dataIndex: 'last_name',
      width: 150,
    },
    {
      title: t('pinfl'),
      dataIndex: 'pinfl',
    },
    {
      title: t('series_n_number_passport'),
      dataIndex: 'id_card',
      align: 'center',
      width: 130,
    },
    {
      align: 'end',
      render: item => {
        const row = (
          <a
            href={`/admin/clients?client_id=${item?.id}&general`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVGIcon className="call-modal__client-show" iconPath={'show'} />
          </a>
        );
        return emptyRow(item, row);
      },
    },
  ];

  return (
    <div>
      <Modal
        title={<h5 className="headModalExtraServices">Клиенты</h5>}
        visible={open}
        footer={false}
        width={800}
        onCancel={handleCancel}
        className="modalAdd ModalClients"
        closeIcon={<SVGIcon iconPath="close" />}
      >
        <div className="call-modal__clients-table">
          <Table
            columns={tableColumns}
            dataSource={data?.clients ?? []}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CallModal;
