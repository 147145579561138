import { ClientService } from '../../services/client.service';

export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_CLIENT_FILTERS = 'FETCH_CLIENT_FILTERS';
export const FETCH_CLIENT_INFO = 'FETCH_CLIENT_INFO';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENTS = 'DELETE_CLIENTS';
export const SET_CLIENT_QUERY_PARAM = 'SET_CLIENT_QUERY_PARAM';
export const CREATE_CLIENT_ADDRESS = 'CREATE_CLIENT_ADDRESS';
export const UPDATE_CLIENT_ADDRESS = 'UPDATE_CLIENT_ADDRESS';
export const DELETE_CLIENT_ADDRESS = 'DELETE_CLIENT_ADDRESS';
export const FETCH_CLIENT_ORDERS = 'FETCH_CLIENT_ORDERS';
export const FETCH_CLIENT_ORDERS_STATUS = 'FETCH_CLIENT_ORDERS_STATUS';
export const RESET_CLIENT_ORDERS = 'RESET_CLIENT_ORDERS';
export const UPDATE_CLIENT_LOCATION = 'UPDATE_CLIENT_LOCATION';
export const FETCH_CLIENT_LOCATION = 'FETCH_CLIENT_LOCATION';

export const fetchClients = (params, resetData = true) => async dispatch => {
  const res = await ClientService.getClients(params);
  return res.data;
};

export const fetchClientsStore = (
  params,
  resetData = true,
) => async dispatch => {
  const res = await ClientService.getClients(params);
  dispatch({
    type: FETCH_CLIENTS,
    payload: {
      data: res.data?.data || [],
      dataLength: res.data.meta?.total || 0,
      dataTo: res.data.meta?.to || 0,
      resetData,
    },
  });
  dispatch({ type: FETCH_CLIENT_INFO, payload: res.data.info });
  return res.data;
};

export const fetchClientFilters = params => async dispatch => {
  const res = await ClientService.getClientFilters(params);
  dispatch({ type: FETCH_CLIENT_FILTERS, payload: res.data.data });
};

export const searchClients = (
  search,
  client_type,
  params,
) => async dispatch => {
  const res = await ClientService.getClients({
    without_pagination: 1,
    'filter[search]': search,
    'filter[client_type]': client_type,
    'filter[status]': 1,
    ...params,
  });
  return res.data;
};

export const createClient = client => async dispatch => {
  const res = await ClientService.createClient(client);
  dispatch({ type: CREATE_CLIENT, payload: res.data.data });
  return res.data;
};

export const updateClient = client => async dispatch => {
  const res = await ClientService.updateClient(client);
  dispatch({ type: UPDATE_CLIENT, payload: res.data.data });
  return res.data;
};

export const updateLocationClient = (id, location) => async dispatch => {
  const res = await ClientService.updateLocationClients(id, location);
  dispatch({ type: UPDATE_CLIENT_LOCATION, payload: res.data.data });
  return res;
};

export const deleteClients = items => async dispatch => {
  const ids = items.map(item => item.id);
  const res = await ClientService.deleteClients(ids);
  dispatch({ type: DELETE_CLIENTS, payload: ids });
  return res.data.message;
};

export const createClientAddress = (id, address) => async dispatch => {
  const res = await ClientService.addClientAddress(id, address);
  dispatch({
    type: CREATE_CLIENT_ADDRESS,
    payload: { id, address: res.data.data },
  });
  return res.data.message;
};

export const updateClientAddress = (id, address) => async dispatch => {
  const res = await ClientService.updateClientAddress(id, address);
  dispatch({
    type: UPDATE_CLIENT_ADDRESS,
    payload: { id, location: res.data.data },
  });
  return res.data.message;
};

export const deleteClientAddress = address => async dispatch => {
  const res = await ClientService.deleteClientAddress(
    address.client_id,
    address.id,
  );
  dispatch({
    type: DELETE_CLIENT_ADDRESS,
    payload: { id: address.client_id, address },
  });
  return res.data.message;
};

export const fetchClientOrders = (
  client,
  params,
  resetData = true,
) => async dispatch => {
  if (resetData) {
    dispatch({
      type: FETCH_CLIENT_ORDERS,
      payload: { data: [], meta: {}, resetData },
    });
  }
  const res = await ClientService.getOrders(client.id, params);
  dispatch({ type: FETCH_CLIENT_ORDERS, payload: { ...res.data, resetData } });

  const statuses = await ClientService.getOrderStatuses(client.id, params);
  dispatch({
    type: FETCH_CLIENT_ORDERS_STATUS,
    payload: { ...statuses.data, resetData },
  });
};

export const fetchClientLocation = params => async dispatch => {
  const res = await ClientService.getLocationsClient(params);
  dispatch({ type: FETCH_CLIENT_LOCATION, payload: res.data });
  return res.data;
};
