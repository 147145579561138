import { message } from 'antd';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { CallService } from '../../services/call.service';
import { config } from '../../config';

export const OPEN_MODAL = 'OPEN_MODAL';
export const SET_CONNECTED = 'SET_CONNECTED';
export const SET_DATA = 'SET_DATA';
export const SET_PUSHER = 'SET_PUSHER';
export const CHECK_CALL = 'CHECK_CALL';
export const MIN_MODAL = 'MIN_MODAL';

export const handleCallModal = type => async dispatch => {
  await dispatch({ type: OPEN_MODAL, payload: type });
  return type;
};

export const handleMinModal = type => async dispatch => {
  await dispatch({ type: MIN_MODAL, payload: type });
  return type;
};

export const checkCall = id => async dispatch => {
  if (id) {
    const res = await CallService.checkCall(id);

    const LocalCallData = JSON.parse(localStorage.getItem('call-data'));
    if (!res.data?.success) {
      dispatch(setData(LocalCallData));
      handleCallModal(true)(dispatch);
    }
    await dispatch({ type: CHECK_CALL, payload: res.data?.success });
    return res.data?.success;
  }
};

export const setCallData = data => async dispatch => {
  await dispatch({ type: SET_DATA, payload: data });
};

export const setConnected = connected => ({
  type: SET_CONNECTED,
  payload: connected,
});

export const setData = data => ({
  type: SET_DATA,
  payload: data,
});

export const setPusher = pusher => ({
  type: SET_PUSHER,
  payload: pusher,
});

export const initializeSocket = () => dispatch => {
  const LocalCallData = JSON.parse(localStorage.getItem('call-data'));

  checkCall(LocalCallData?.call_id)(dispatch);

  const pusher = new Pusher('ABCDEFG', {
    cluster: 'mt1',
    wsHost: config.API_WEBSOCKET,
    wsPort: 443,
    forceTLS: true,
  });

  const echo = new Echo({
    broadcaster: 'pusher',
    client: pusher,
  });

  echo.connector.pusher.connection.bind('connected', () => {
    dispatch(setConnected(true));
    console.info('Подключение к серверу восстановлено!');
    message.success('Подключение к серверу восстановлено!');
  });

  echo.connector.pusher.connection.bind('disconnected', () => {
    dispatch(setConnected(false));
    console.error('Нет связи с сервером!');
    message.error('Нет связи с сервером!!!');
  });

  echo.connector.pusher.connection.bind('state_change', states => {
    if (states.current === 'disconnected') {
      localStorage.removeItem('pusherTransportTLS');
      echo.connector.pusher.connect();
    }
  });

  echo.channel('iptel').listen('IpTelEvent', e => {
    if (e?.direction) {
      dispatch(setData(e));
      localStorage.setItem('call-data', JSON.stringify(e));
    } else {
      message.error(e?.message ?? 'Error');
    }
  });

  dispatch(setPusher(pusher));
};

export const changeClientId = newData => dispatch => {
  dispatch(setData(newData));
};
