import {
  CREATE_FLIGHT,
  DELETE_FLIGHT,
  DELETE_FLIGHTS,
  FETCHING_FLIGHTS,
  FETCH_FLIGHTS,
  FETCH_FLIGHT_FILTERS,
  PUSH_FLIGHTS,
  SET_FLIGHT_QUERY_PARAM,
  UPDATE_FLIGHT,
  UPDATE_FLIGHTS,
} from './flights.actions';

const initialState = {
  loading: false,
  flights: [],
  info: [],
  queryParams: {},
  flightsLength: 0,
  filters: [],
  flightsTo: 0,
};

export const FlightReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_FLIGHTS:
      return {
        ...state,
        loading: payload,
      };
    case FETCH_FLIGHTS:
      return {
        ...state,
        flights: payload.data,
        info: payload.info,
        flightsLength: payload.dataLength,
        flightsTo: payload.dataTo,
      };
    case FETCH_FLIGHT_FILTERS:
      return {
        ...state,
        filters: payload,
        queryParams: {},
      };
    case SET_FLIGHT_QUERY_PARAM:
      const isEmpty = payload && !Object.keys(payload).length;
      return {
        ...state,
        queryParams: isEmpty
          ? {}
          : {
              ...state.queryParams,
              ...payload,
            },
      };
    case PUSH_FLIGHTS:
      console.log('payload.dataTo', payload.dataTo);
      return {
        ...state,
        flights: [...state.flights, ...payload.data],
        flightsLength: payload.dataLength,
        flightsTo: payload.dataTo ?? 0,
      };
    case CREATE_FLIGHT:
      return {
        ...state,
        flights: [payload, ...state.flights],
        flightsLength: state.flightsLength + 1,
      };

    case UPDATE_FLIGHT:
      return {
        ...state,
        flights: state.flights.map(flight =>
          flight.id === payload.id ? payload : flight,
        ),
      };
    case UPDATE_FLIGHTS:
      return {
        ...state,
        flights: state.flights.map(flight =>
          payload.find(p => p.id === flight.id)
            ? payload.find(p => p.id === flight.id)
            : flight,
        ),
      };
    case DELETE_FLIGHT:
      return {
        ...state,
        flights: state.flights.filter(flight => flight.id !== payload),
        flightsLength: state.flightsLength - 1,
      };
    case DELETE_FLIGHTS:
      return {
        ...state,
        flights: state.flights.filter(flight => !payload.includes(flight.id)),
        flightsLength: state.flightsLength - payload.length,
      };

    default:
      return state;
  }
};
