import axios from 'axios';
import { config } from '../config';

const http = axios.create({
  baseURL: config.API_ROOT,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
  timeout: 720000,
});

http.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');
  if (branch) config.headers['X-SESSION-ID'] = branch;
  config.headers.Authorization = `Bearer ${token}`;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  config.cancelToken = source.token;

  if (!window.navigator.onLine) {
    const error = new Error('No internet connection');
    error.offline = true;
    throw error;
  }

  return config;
});

http.interceptors.response.use(
  response =>
    new Promise((resolve, _) => {
      resolve(response);
    }),
  error => {
    return Promise.reject(error);
  },
);

const test_http = axios.create({
  baseURL: 'https://api.turonexpress.com',
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
  timeout: 720000,
});

test_http.interceptors.request.use(function (config) {
  const token =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYmZiOTFiNTNhYTdhMDBiMjg3MzU3OTQ3N2IwMDU3NmU1ZmY2MjlmYTNmMDBmOTUwZjhiMzkwMTZjYWZkY2JkZDRjYjJkMWM1OTc0YjRlMjQiLCJpYXQiOjE3MjE4MTgyNzgsIm5iZiI6MTcyMTgxODI3OCwiZXhwIjoxNzUzMzU0Mjc4LCJzdWIiOiIyNSIsInNjb3BlcyI6WyJ1c2VycyJdfQ.EWGu0mxy_KTY1iZFWN5xTQyV42tcKEvKXMpDqsTMOrmtmWj5tJiJOt7XVf2r-LDQ7fQ6Nn6pxUJ_8S5vfFRJE-xyrD9PY6Yb_bre0wEemueqZM_k-CRG6Vg4XPSOxhyrtkcDrb5bO6GnA8SFAjiNp5nSBd8JdajXtB3Y-OGg-2wLpd-lqp0abRURohH9_nJ4qnE0eHTQdx4dIEKFrMWddtxMLYTXl-cP4evChlqPUfO8zQgDxAGBHyB_9uiwKNYRQ8N1gPRbWM0QAp92sAA8MDhe_nW2CQ8IfYLzq6wXU1h1yL9uMSnCy6jJFBdjwBy9Hv--BQ4QtORpV9pdRCv_TvuEnwJKAA8fysXXig2MSV4wYbF5fuc54bnjCSq1EjSyZSLztPOV4zJc3FJFL4qth17ETrBuod8pORkK2sE4Fdf7U6btMF-o-QNB55OKx8i3OGLA7utMLelZkvOa2kM-FfysHx-_hQGCAT27AZY6RchRICCuwI-Rr7qEcWQAIuxN7SPZNy9NgwC9Erq2BrVhpYR5QIi8Eo1bBqGbiFlZGmQM6uGm-K9boKZkiBvZLrsdYWZwVtg6fj1SEllLAMgKL7We382dxfFGcSVuu07tz-mfwhh5_Ln5P1qae-BIqav17tZ4EzgFySOs2Y0cY7XkBh22du1BP5ZRC6VllvjDHzs';
  const branch = localStorage.getItem('branch');
  if (branch) config.headers['X-SESSION-ID'] = branch;
  config.headers.Authorization = `Bearer ${token}`;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  config.cancelToken = source.token;

  if (!window.navigator.onLine) {
    const error = new Error('No internet connection');
    error.offline = true;
    throw error;
  }

  return config;
});

test_http.interceptors.response.use(
  response =>
    new Promise((resolve, _) => {
      resolve(response);
    }),
  error => {
    return Promise.reject(error);
  },
);

export { http, test_http };
