import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { formatUnit } from '../../utils/format-unit';

export const UnitComponent = ({ value, type, className = '', ...rest }) => {
  const [t] = useTranslation();
  const children = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 })
    .format(value || 0)
    .replace(',', '.');
  const formattedValue = useMemo(() => {
    switch (type) {
      case 'usd':
        return `$ ${children}`;
      case 'quantity':
        return t('unit-quantity', { value: children });
      case 'weight':
        return t('unit-weight', { value: children });
      case 'percent':
        return `${children} %`;
      case 'length':
        return t('unit-length', { value: children });
      default:
        return children;
    }
  }, [children, type]);
  return (
    <span className={['unit', className].join(' ')} {...rest}>
      {formattedValue}
    </span>
  );
};

UnitComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['quantity', 'usd', 'weight', 'percent']),
};
