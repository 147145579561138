import {
  FETCH_DELIVERY,
  FETCH_DELIVERY_FILTERS,
  FETCH_DELIVERY_INFO,
  SET_DELIVERY_QUERY_PARAM,
} from './delivery.actions';

const initialState = {
  delivery: [],
  filters: [],
  info: [],
  queryParams: {},
  deliveryLength: 0,
  deliveryTo: 0,
  paymentMethods: [],
};

export const DeliveryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DELIVERY:
      return {
        ...state,
        delivery: payload.resetData
          ? payload.data
          : [...state.delivery, ...payload.data],
        deliveryLength: payload.dataLength,
        deliveryTo: payload.dataTo,
      };
    case FETCH_DELIVERY_FILTERS:
      return { ...state, filters: payload };
    case FETCH_DELIVERY_INFO:
      return { ...state, info: payload };
    case SET_DELIVERY_QUERY_PARAM:
      const isEmpty = payload && !Object.keys(payload).length;
      return {
        ...state,
        queryParams: isEmpty
          ? {}
          : {
              ...state.queryParams,
              ...payload,
            },
      };
      return {
        ...state,
        batches: state.batches.filter(batch => !payload.includes(batch.id)),
        batchesLength: state.batchesLength - 1,
      };
    default:
      return state;
  }
};
